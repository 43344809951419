import { SESSION_STORAGE } from '~/constants/storedPropertiesNames';

export default defineNuxtRouteMiddleware((to) => {
  if (import.meta.server) {
    return;
  }

  if (!to.path.includes('/cabinet/personal-data')) {
    sessionStorage.removeItem(SESSION_STORAGE.openCashModal);
  }
});
